import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';

import api from '../../../../services/api';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';

import '../PartialEdit/PartialEdit.scss';
import DoubleCalendar from '../../../Calendar/DoubleCalendar';
import DoubleCalendarTicket from '../../../Calendar/DoubleCalendarTicket';
import DropdownHours from '../../../DropdownHours/DropdownHours';
import { useTranslation } from 'react-i18next';
import formatTimeRange from '../../../../services/formatTimeRange';

export interface propPartialEdit {
    setPartialReschedulingModal: any;
    currentReservation: any;
    setModalShow: any;
    setModalLog: any;
    setModalMessage: any;
}

const PartialRescheduling: React.FC<propPartialEdit> = ({
    setPartialReschedulingModal,
    currentReservation,
    setModalShow,
    setModalLog,
    setModalMessage,
}: propPartialEdit) => {
    const [t] = useTranslation();

    const [item, setItem] = useState<any>(null);
    const [acessCode, setAcessCode] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selected, setSelected] = useState<number[]>([]);
    const [itemToBeListed, setItemToBeListed] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
    //const [isLoad, setIsLoad] = useState<boolean>(false);
    //const local = JSON.parse(localStorage.getItem('bpaSigAuth') || '{}');
    //const useId = local?.user?.id;
    const [date, setDate] = useState<any>(null);
    const [option, setOption] = useState<any>(null);
    const [isIntegration, setIsIntegration] = useState<any>(false);
    const [dateHours, setDateHours] = useState<any>(item?.startTimeLocal?.split("T")[1]);
    const [dateTour, setDateTour] = useState<any>([]);
    const [errorOnDate, setErrorOnDate] = useState<string | null>(null);
    //const [lodingDateInfo, setLodingDateInfo] = useState<boolean>(true);

    useEffect(() => {
        const getProductInfo = async (obj: any) => {
            setErrorOnDate(null);
            setItem(null);

            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
            };

            let apiName: string = obj.productType === 4 ? `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTicketAsync` : `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTourAsync`;

            try {
                const res = await api.post(apiName,
                    {
                        productCode: `${obj.productCode}`,
                        lang: "BR",
                        Slug: '',
                        idCanal: 2
                    },
                    config);
                if (res.status === 200) {
                    if (res?.data?.statusCode === 200) {
                        let hasModality: boolean = res.data.data.modalities?.length > 0//res.data.data["modalities"].find((elem: any) => { return ((obj.productType === 4 ? elem.uniqueModCode : elem.prodModUniqueCode) === obj.ticketModUniqueCode) });

                        setItem(obj);
                        setDateHours(obj?.startTimeLocal?.split("T")[1]);

                        if (hasModality) {
                            setOption(res.data.data.modalities[0]);
                        } else {
                            setErrorOnDate(t("myReservations.partial.error01"));
                        }
                    } else {
                        setErrorOnDate(t("myReservations.partial.error02"));
                    }
                } else {
                    setErrorOnDate(t("myReservations.partial.error01"));
                }
            } catch (error: any) {
                setErrorOnDate(t("myReservations.partial.error01"));
            }
        };

        const getDetails = async () => {
            setItem(null);

            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
            };
            try {
                const res = await api.get(`/api/NewReservations/GetNewReservationsByIdAsyncSite/${currentReservation?.idNewItems}`, config);
                if (res.status !== 400) {
                    if (res?.data?.statusCode === 200) {

                        /* setItem(res?.data?.data);
                        setDateHours(res?.data?.data?.startTimeLocal?.split("T")[1]);
                        setOption({
                            allTarif: res?.data?.data?.allTarif,
                            prodModCode: res?.data?.data?.idModalidade,
                            sellingType: res?.data?.data?.sellingType,
                            tarif: {
                                tarCode: res?.data?.data?.allTarif[0]?.tarCode
                            }
                        }); */
                        getProductInfo(res.data.data);
                    } else {
                        setErrorOnDate(t("myReservations.partial.error01"));

                    }
                }
            } catch (error: any) { console.log(error.response) }
        };

        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentReservation?.idNewItems === undefined) {
            currentReservation.idNewItems = currentReservation?.idItem;
        }

        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        async function listVoucher() {
            try {
                const { data } = await api.post(
                    `/api/Reservations/ListAllItemEdit`,
                    {
                        idItem: currentReservation?.idNewItems,
                    },
                    config,
                );
                if (data.status !== 400) {
                    setLoadingRefresh(false);
                    setAcessCode(data.data.data);
                }
            } catch (error: any) {

            }
        }
        listVoucher();
    }, [currentReservation, currentReservation?.idNewItem]);

    function convertDate(cell: any) {
        let dateFormatted: any = cell?.split(' ')[0].split('/').reverse().join('-') + "T00:00:00";

        return (
        <div className='d-flex flex-column'>
            <span>
                {cell?.split(' ')[0]}
            </span>
            <span>
                {formatTimeRange(cell?.split(' ')[1], undefined, dateFormatted, option?.productCode)}
            </span>
        </div>
        );
    }

    function convertToMobile(cell: any, row: any) {
        let dateFormatted: any = cell?.split(' ')[0].split('/').reverse().join('-') + "T00:00:00";
        return (
            <>
                <div className='d-flex flex-column'>
                    <span className='mb-1'><strong>{t("myReservations.partial.date")}:</strong> {row.date?.split(' ')[0]}</span>
                    <span className='mb-1'><strong>{t("myReservations.partial.hour")}:</strong> {formatTimeRange(cell?.split(' ')[1], undefined, dateFormatted, option?.productCode)}</span>
                    <span className='mb-1'><strong>Ticket:</strong> {row.qr}</span>
                    <span className='mb-1'><strong>{t("myReservations.partial.situacion")}:</strong> {row.texto}</span>
                </div>
            </>
        )
    }


    const columns = [
        { dataField: 'date', text: t("myReservations.partial.date"), formatter: convertDate },
        { dataField: 'qr', text: 'Ticket' },
        { dataField: 'texto', text: t("myReservations.partial.situacion"), },
        /* { dataField: 'startTimeLocal', text: 'Data Futura', formatter: inputDate }, */
    ];

    const columnsMobile = [
        { dataField: 'date', text: 'Data', formatter: convertToMobile },
    ];

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        );
    }

    const loadingColumns = [
        {
            dataField: 'contasd',
            text: 'Carregando Tickets',
            formatter: loadingFunc,
        },
    ];

    const loadingProducts = [
        { contas: '' },
        { contas: '' },
        { contas: '' },
        { contas: '' },
        { contas: '' },
    ];
    /* [END] loading table */

    const rowClasses = (row: any) => {
        return '';
    };


    useEffect(() => {
        var aux: any = [];
        itemToBeListed.forEach((elem: any) => {
            aux.push(elem.qr);
        });
        setSelected(aux);
    }, [itemToBeListed])

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: (row: any, isSelect: any) => {
            if (isSelect === true) {
                var add: any;
                add = [...itemToBeListed, row];
                setItemToBeListed(add);
            } else {
                var exclude: any = [];
                for (var i: any = 0; i < itemToBeListed.length; i++) {
                    if (itemToBeListed[i].qr !== row.qr) {
                        exclude.push(itemToBeListed[i]);
                    }
                }

                setItemToBeListed(exclude);
            }
        },
        onSelectAll: (isSelect: any, rows: any) => {
            if (isSelect === true) {
                setItemToBeListed(rows);
            } else {
                setItemToBeListed([]);
            }
        },
    };

    const UpdateAcessCodes = async () => {
        if (date === null || date === '') {
            setModalLog(1);
            setModalMessage(t("myReservations.partial.selectPartial"));
            setModalShow(true);

            return;
        }

        if (dateHours === null || dateHours === '' || dateHours === undefined) {
            setModalLog(1);
            setModalMessage(t("myReservations.partial.selectPartial"));
            setModalShow(true);

            return;
        }

        setLoading(true);
        setModalLog(null);
        setModalMessage("Carregando...")
        setModalShow(true);

        let qtdAdults: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Adult') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdChild: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Child') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdInfant: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Infant') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdElders: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Elders') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)
        let qtdStudent: number = item.qrCodeAccessReservationDTO?.filter((elem: any) => { return (elem.status === 1) })?.filter((elem: any) => { return (elem.faixaLabel === 'Student') })?.map((elem: any) => { return (1) })?.reduce((acum: any, value: any) => acum + value, 0)


        let newDate: string = date?.split('/').reverse().join('-');

        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}`, 'Content-Type': 'application/json', "ngrok-skip-browser-warning": "69420" },
        };
        try {
            if (itemToBeListed.length > 0) {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/api/Checkout/EditEnviaCheckoutSite`,
                    {
                        "firstName": item.nome,
                        "lastName": item.sobrenome,
                        "phone": item.telefone,
                        "email": item.email,
                        "idTarif": item.idTarifario,
                        "internalNotes": item.internalNotesItem,
                        "IdNewItem": item.idItem,
                        "date": `${newDate}T${dateHours}`,
                        "hora": dateHours,
                        "idBooking": item.idBookings,
                        "BookingLoc": item.bookingLoc,
                        "Foreign": 1,
                        "Language": 1,
                        "newProduct": item.idCanal === 0 ? 1 : 0,
                        "adults": qtdAdults,
                        "childs": qtdChild,
                        "infants": qtdInfant,
                        "elders": qtdElders,
                        "students": qtdStudent,
                        "accessCodeListToEdit": itemToBeListed?.map((elem: any) => { return (elem.qr) })
                    },
                    config,
                );
                if (data.status !== 400) {

                    if (data.data.log === 0 || data.data.log === '0') {
                        setPartialReschedulingModal(false);
                        setModalLog(data.data.log);
                        setModalMessage(data.data.texto);
                        setLoading(false);
                    } else if (data.data.log === 1 || data.data.log === '1') {
                        setModalLog(data.data.log);
                        setLoading(false);
                        setModalMessage(data.data.texto);
                    }
                }
            } else {
                setModalLog(1);
                setLoading(false);
                setModalMessage('Por favor, selecione um ticket para ser alterado');
            }
        } catch (error: any) {
            setLoading(false);
            setModalLog(1);
            setModalMessage("Erro ao processar operação!");
        }
    };

    function changePriceDateTour(obj: any) {
        setDateTour(obj);

        setDate(obj[0].data);
        setDateHours(obj[0].time)
    }

    if (acessCode !== null && loadingRefresh === false) {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">
                            {t("myReservations.partial.title")}
                        </h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-primary"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setPartialReschedulingModal(false);
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <div className="bg-rescheduling">
                    <hr className="w-100 my-2" style={{ borderBottom: '1px solid' }}></hr>

                    <div className='mt-3 d-flex justify-content-between align-items-center row'>
                        <Col md={12} className='mb-2'>
                            <small className='col-md-9' style={{ fontWeight: 600 }}>*{t("myReservations.partial.subtitle")}</small>
                        </Col>

                        {
                            errorOnDate === null ?
                                <Col md={6} className='mb-2'>
                                    {option !== null && item !== null ?
                                        <div className='d-flex flex-column'>
                                            <span className="subtitle-modal">{t("myReservations.partial.date")}</span>
                                            {item?.productType === 4
                                                ?
                                                <DoubleCalendarTicket
                                                    modalityID={option?.productCode}
                                                    product={option}
                                                    productCode={item?.productCode}
                                                    changePriceDateTour={changePriceDateTour}
                                                    dateTour={[{}]}
                                                    index={0}
                                                    setRanges={() => { }}
                                                    isIntegration={isIntegration}
                                                    setIsIntegration={setIsIntegration}
                                                    setApiError={() => { }}
                                                ></DoubleCalendarTicket>
                                                :
                                                <DoubleCalendar
                                                    modalityID={option?.productCode}
                                                    product={option}
                                                    productCode={item?.productCode}
                                                    changePriceDateTour={changePriceDateTour}
                                                    dateTour={[{}]}
                                                    index={0}
                                                    setRanges={() => { }}
                                                    setIsIntegration={setIsIntegration}
                                                    setApiError={() => { }}
                                                ></DoubleCalendar>
                                            }
                                        </div>
                                        :
                                        <>
                                            <div className="load small"></div>
                                        </>
                                    }
                                </Col>
                                :
                                <Col md={6}>
                                    <small className='text-danger'>{errorOnDate}</small>
                                </Col>
                        }
                        <Col md={6} className='mb-2'>
                            {date
                                ?
                                <>
                                    <span className="subtitle-modal">{t("myReservations.partial.hour")}</span>

                                    <div className="border" style={{ border: '1px solid #cecece' }}>
                                        <DropdownHours
                                            changePriceDateTour={changePriceDateTour}
                                            dateTour={dateTour}
                                            index={0}
                                            setPeopleDisponible={() => { }}
                                            info={item}
                                            isDetailsProduct={true}

                                        />
                                    </div>
                                </>
                                :
                                <span className="response-modal"><b></b></span>
                            }
                        </Col>
                    </div>

                    <div className="table-default mt-2">
                        {/* <FontAwesomeIcon
                            icon={['fad', 'redo-alt']}
                            className="reload-btn text-primary mr-2 d-md-block d-none"
                            onClick={() => {
                                getAccessCode(currentReservation?.idNewItems);
                            }}
                            size="1x"
                        /> */}
                        <div className='d-none d-md-block'>
                            <BootstrapTable
                                bootstrap4
                                keyField="qr"
                                selectRow={selectRow}
                                data={acessCode}
                                columns={columns}
                                rowClasses={rowClasses}
                            />
                        </div>
                        <div className='d-block d-md-none'>
                            <BootstrapTable
                                bootstrap4
                                keyField="qr"
                                selectRow={selectRow}
                                data={acessCode}
                                columns={columnsMobile}
                                rowClasses={rowClasses}
                            />
                        </div>
                    </div>
                </div>
                <Modal.Footer className="d-flex justify-content-end px-0 pb-0">
                    <button className="btn btn-outline-primary" onClick={() => { setPartialReschedulingModal(false) }}>
                        {t("myReservations.partial.cancel")}
                    </button>
                    <button className="btn btn-primary" onClick={UpdateAcessCodes}>
                        {loading === true ? <div className="load small white"></div> : 'Confirmar'}
                    </button>
                </Modal.Footer>
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column justify-content-center h-100">
                <Modal.Header className="justify-content-between align-items-center">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">
                            {t("myReservations.partial.title")}
                        </h4>
                    </div>
                    <FontAwesomeIcon
                        icon={['fal', 'times']}
                        className="mx-3 text-primary"
                        size="lg"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            setPartialReschedulingModal(false);
                        }}
                    />
                </Modal.Header>
                <div className="bg-voucher-download">
                    <hr className="w-100 my-2" style={{ borderBottom: '1px solid' }}></hr>
                    <div className="table-default loading">
                        <BootstrapTable
                            bootstrap4
                            keyField="qr"
                            selectRow={selectRow}
                            data={loadingProducts}
                            columns={loadingColumns}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default PartialRescheduling;