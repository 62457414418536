import React, { useState } from "react";

import { Row, Col } from 'react-bootstrap';

import { Controller, useForm } from "react-hook-form";

import Form from 'react-bootstrap/Form';
import GetBanks from "../../../../../../components/C2Points/GetBanks";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import ModalPrivacy from "../../../../../../components/Modal/ModalPrivacy/ModalPrivacy";
import PrivacyNoticeCaracolPT from "../../../../../PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolPT/PrivacyNoticeCaracolPT";
import PrivacyNoticeCaracolEN from "../../../../../PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolEN/PrivacyNoticeCaracolEN";
import PrivacyNoticeCaracolES from "../../../../../PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolES/PrivacyNoticeCaracolES";
import PrivacyTerms from "../../../../../../components/PrivacyTermsNotice/PrivacyTerms";
import TermsOfUseCaracolPT from "../../../../../TermsOfUse/TermsOfUseCaracol/components/TermsOfUseCaracolPT/TermsOfUseCaracolPT";
import TermsOfUseCaracolEN from "../../../../../TermsOfUse/TermsOfUseCaracol/components/TermsOfUseCaracolEN/TermsOfUseCaracolEN";
import TermsOfUseCaracolES from "../../../../../TermsOfUse/TermsOfUseCaracol/components/TermsOfUseCaracolES/TermsOfUseCaracolES";
import TermsUse from "../../../../../../components/TermsUse/TermsUse";
import i18next from "i18next";
//import DefaultContract from "../../../../../../components/DefaultContract/DefaultContract";
import api from "../../../../../../services/api";
//import GetSalesChannelAffiliate from "../../../../../../components/C2Points/GetSalesChannelAffiliate";

export interface propForm {
    action: any,
    back: any,
    supplierControlObject: any,
    setModalSuccess?: any,
    loading?: any,
    isRecaptcha?: any,
    setIsRecaptcha?: any
};

const FormBank: React.FC<propForm> = ({
    action, back, supplierControlObject, setModalSuccess, loading, isRecaptcha, setIsRecaptcha
}: propForm) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [privacy, setPrivacy] = useState<any>(false);

    const { t } = useTranslation();
    const validated = false;

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            codBank: supplierControlObject?.codBank ? { id: supplierControlObject.codBank, description: supplierControlObject.codBankDesc } : '',
            accountType: supplierControlObject ? supplierControlObject.accountType : '1',
            agency: supplierControlObject ? supplierControlObject.agency : '',
            digitAgency: supplierControlObject ? supplierControlObject.digitAgency : '',
            account: supplierControlObject ? supplierControlObject.account : '',
            digitAccount: supplierControlObject ? supplierControlObject.digitAccount : '',
            checkPolice: supplierControlObject ? supplierControlObject.checkPolice : '',
            /*             idCanal: supplierControlObject?.idCanal ? { salesChannelName: supplierControlObject.descCanal, idSalesChannel: supplierControlObject.idCanal } : '' */
        },
    });

    const accountType = watch(`accountType`, "1");

    const onSubmit = (data: any) => {
        /* data.descCanal = data.idCanal.salesChannelName;
        data.idCanal = data.idCanal.idSalesChannel; */

        if (isRecaptcha !== null) {
            data.codBankDesc = data.codBank.description;
            data.codBank = data.codBank.id;
            //data.digitAgency = undefined;
            data.digitAccount = Number(data.digitAccount);

            setModalSuccess(true)
            action(data);
        } else {
            alert("Por favor, selecione o reCAPTCHA se deseja prosseguir")
        }
    }

    function handleRacaptcha(value: any) {
        setIsRecaptcha(value);
    }

    function downloadBase64(exp: any) { // file: bytes/string, name: string
        // const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
        // const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = exp;
        link.setAttribute(
            'download',
            'contrato.pdf',
        );
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    }

    const handleClickDownload = async () => {

        const token = localStorage.getItem('GroupId') || '{}';

        try {
            const res = await api.post(`api/Supplier/DownloadContratoAgencia`, {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        'Authorization': `Bearer ${token}`
                    },
                    responseType: 'blob'
                });
            if (res.status !== 400) {
                /* Faz a verificação se o valor recebido é uma instância blob. Se for uma instância blob faço o download direto. Caso não seja uma instância, acesso a resposta da api como um JSON para pegar a mensagem de erro */
                const isJsonBlob = (data: any) => data instanceof Blob && data.type === "application/json";
                const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
                /*  */

                // setModalLog(JSON.parse(responseData)?.data?.log);
                // setModalMessage(JSON.parse(responseData)?.data?.texto);

                if (JSON.parse(responseData)?.data?.log === 0) {
                    downloadBase64(JSON.parse(responseData)?.data?.data);
                }
            } else {
                // setModalLog(1);
                // setModalMessage("Erro ao processar operação");
            }
        } catch (error: any) { }
    }

    return (
        <>
            <div>
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <Row>
                        <Form.Group as={Col} md={6} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formBank")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="codBank"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <GetBanks propsField={field} propsErrors={errors} propsLabel="Banco" />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="codBank"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={6} controlId="formVehiclesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formAccountType")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="accountType"
                                defaultValue={accountType}
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Select
                                        {...field}
                                        aria-invalid={errors?.accountType ? "true" : ""}
                                        label="Categoria"
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        required
                                    >
                                        {/* <option value="" selected disabled>Selecione</option> */}
                                        <option value="1" selected>Corrente</option>
                                        <option value="2">Poupança</option>
                                    </Form.Select>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="accountType"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} sm={4} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formAgency")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="agency"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.agency ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="agency"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={2} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formDigit")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="digitAgency"
                                //rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        //aria-invalid={errors?.digitAgency ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                        onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        maxLength={1}
                                    />
                                )}
                            />
                            {/* <ErrorMessage
                                errors={errors}
                                name="digitAgency"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            /> */}
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formAccount")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="account"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.account ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="account"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={2} controlId="formResourcesType" className="mb-3">
                            <Form.Label>
                                {t("affiliates.formDigit")}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="digitAccount"
                                rules={{ required: { value: true, message: t("affiliates.formError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.digitAccount ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="new-password"
                                        onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        maxLength={1}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="digitAccount"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>
                    {/* 
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                            <Form.Label>Canal</Form.Label>
                            <Controller
                                control={control}
                                name="idCanal"
                                rules={{ required: { value: true, message: 'Por favor, informe o canal.' } }}
                                render={({ field }: any) => (
                                    <GetSalesChannelAffiliate propsField={field} propsErrors={errors} propsLabel="Canal" />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="idCanal"
                                render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                )}
                            />
                        </Form.Group>
                    </Row> */}

                    <Row className="my-2">
                        <Form.Group as={Col} controlId="BR" className="d-flex">
                            <label htmlFor="chackPolice">
                                <Form.Group>
                                    <Controller
                                        control={control}
                                        name="checkPolice"
                                        rules={{ required: { value: true, message: t("profileNew.errorCheck") } }}
                                        render={({ field }: any) => (
                                            <Form.Check
                                                {...field}
                                                id="chackPolice"
                                                name="check"
                                                className="mr-2"
                                                //defaultChecked={aceptPrivacy}
                                                //disabled={profile}
                                                required
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </label>
                            <div>
                                {t("checkoutSteps.acept")}
                                <ModalPrivacy
                                    type="privacy"
                                    name={t("checkoutSteps.privacy")}
                                    header={t("checkoutSteps.privacy")}
                                    footer="yes"
                                    close={t("privacyTerms.refuse")}
                                    save={t("privacyTerms.accept")}
                                    class="btn-privacy d-flex"
                                    setValue={setValue}
                                    setPrivacy={setPrivacy}
                                >
                                    <div className="modal-checkout">
                                        {
                                            process.env.REACT_APP_CLIENT_NAME === "Caracol"
                                                ?
                                                i18next.language === "pt"
                                                    ?
                                                    <PrivacyNoticeCaracolPT />
                                                    :
                                                    i18next.language === "en"
                                                        ?
                                                        <PrivacyNoticeCaracolEN />
                                                        :
                                                        i18next.language === "es"
                                                            ?
                                                            <PrivacyNoticeCaracolES />
                                                            :
                                                            <PrivacyNoticeCaracolPT />
                                                :
                                                <PrivacyTerms />
                                        }
                                    </div>
                                </ModalPrivacy>
                                {t("checkoutSteps.and")}
                                <ModalPrivacy
                                    type="privacy"
                                    name={t("checkoutSteps.terms")}
                                    header={t("checkoutSteps.terms")}
                                    footer="yes"
                                    close={t("privacyTerms.refuse")}
                                    save={t("privacyTerms.accept")}
                                    class="btn-privacy d-flex"
                                    setValue={setValue}
                                    setPrivacy={setPrivacy}
                                >
                                    <div className="modal-checkout">
                                        {
                                            process.env.REACT_APP_CLIENT_NAME === "Caracol"
                                                ?
                                                i18next.language === "pt"
                                                    ?
                                                    <TermsOfUseCaracolPT />
                                                    :
                                                    i18next.language === "en"
                                                        ?
                                                        <TermsOfUseCaracolEN />
                                                        :
                                                        i18next.language === "es"
                                                            ?
                                                            <TermsOfUseCaracolES />
                                                            :
                                                            <TermsOfUseCaracolPT />
                                                :
                                                <TermsUse />
                                        }
                                    </div>
                                </ModalPrivacy>
                                {t("affiliates.andThe")}
                                <span style={{ cursor: 'pointer', textDecoration: 'underline' }} className="" onClick={() => { handleClickDownload() }}>{t("affiliates.defaultContract")}</span>
                                {/* <ModalPrivacy
                                    type="privacy"
                                    name={t("affiliates.defaultContract")}
                                    header={t("affiliates.titleDefaultContract")}
                                    footer="yes"
                                    close={t("privacyTerms.refuse")}
                                    save={t("privacyTerms.accept")}
                                    class="btn-privacy d-flex"
                                    setValue={setValue}
                                    setPrivacy={setPrivacy}
                                >
                                    <div className="modal-checkout">
                                        <DefaultContract />
                                    </div>
                                </ModalPrivacy> */}
                            </div>
                        </Form.Group>
                        <ErrorMessage
                            errors={errors}
                            name="checkPolice"
                            render={({ message }) => <small style={{ color: "red", marginLeft: "25px" }}>{message}</small>}
                        />
                    </Row>

                    <div className="d-flex flex-column align-items-end pt-2">
                        <div className="mb-3 bg-profile-recaptcha">
                            {
                                loading === false
                                    ?
                                    <ReCAPTCHA
                                        /* sitekey="6LePcgIgAAAAAH1_VmWf1nbBz1CLbJx_6nyeQYic" */
                                        sitekey={`6LcrMYYgAAAAAMt8yWH41E4YDEofQRARG1X43TLw`}
                                        onChange={handleRacaptcha}
                                    />
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    <Row className="d-flex justify-content-end pt-2">
                        <Col md={3}>
                            <button className="btn btn-outline-primary mb-3 mb-md-0" onClick={() => back(2)}>{t("affiliates.formBack")}</button>
                        </Col>
                        <Col md={3}>
                            <button type="submit" className="btn btn-primary">{t("affiliates.formFinalize")}</button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default FormBank;