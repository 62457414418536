import React, { useState, useEffect, Key } from 'react';
import api from "../../../services/api";

export interface propInfo {
    id: any,
    setDetailsModal: any
};

const DetailsReservation: React.FC<propInfo> = ({
    id, setDetailsModal
}: propInfo) => {

    const [item, setItem] = useState<any>(null);

    const { userModel }: any = JSON.parse(localStorage.getItem('bpasiteAuth') || '{}');

    const cellphone = userModel !== undefined ? userModel.cellphone : null;
    const email = userModel !== undefined ? userModel.email : null;


    useEffect(() => {
        const getDetails = async () => {
            const token = localStorage.getItem('bpasiteAuth') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${JSON.parse(token).token.token}`, 'Content-Type': 'application/json' },
            };
            try {
                /* const res = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/NewReservations/GetNewReservationsByIdAsyncSite/741?OrderId=741`, config); */
                const res = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/NewReservations/GetNewReservationsByIdAsyncSite/${id}?OrderId=${id}`, config);
                if (res.status !== 400) {
                    setItem(res.data.data)
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/login';
                }
            }
        };
        getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any, isHour: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        if (isHour === true) {
            return newDate.toLocaleString().split(' ')[1];
        } else {
            return newDate.toLocaleString().split(' ')[0];
        }
    }

    function convertDate(cell: any, isHour: any = false) {
        var date = convertUTCDateToLocalDate(new Date(cell), false);
        var hours = convertUTCDateToLocalDate(new Date(cell), true);
        if (isHour === true) {
            return (
                <>
                    {hours}
                </>
            );
        } else {
            return (
                <>
                    {date}
                </>
            );
        }
    }



    if (item !== null) {
        return (
            <>
                <div className="modal-content">

                    <div className="modal-header bg-primary rounded-0">
                        <h5 className="modal-title text-white" id="modal-1-Title">Detalhes da Reserva</h5>
                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close" onClick={() => { setDetailsModal(false) }}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div id="js">            <div className="modal-header bg-light rounded-0 border-0 d-flex flex-row flex-wrap justify-content-between align-items-end">


                        <div className="col-12 col-md-8">
                            {/* <h5 className=""><i className="icofont icofont-calendar"></i> Raquel Martinez Roca</h5> */}
                            <h4 className="pt-1">{item.productName}</h4>
                        </div>





                        <div className="col-12 col-md-4">
                            <div className="w-100 mb-2 d-flex justify-content-center"><span className="text-uppercase bg-white text-dark font-weight-bold p-2 border-primary border-bottom"><span data-vouchercampo="code"> Código da reserva:</span> <span data-voucherinfo="code">{item.bookingLoc}</span><span className="d-none" data-voucherinfo="item">17205</span></span></div>
                        </div>

                    </div>


                        <div id="printThisDT">

                            <div className="modal-body rounded-0 border-0" style={{ backgroundColor: "#f2f2f2" }}>
                                <div className="">
                                    <div className="col-12">
                                        <div className="row">

                                            <div className="col-md-12 mt-3">
                                                <span className="col-md-12 colla p-0 mt-2 h6 text-uppercase text-primary">
                                                    <i className="fas fa-user text-primary"></i> Dados do Titular da Reserva</span>
                                                <div className="card rounded-0 float-left p-2 w-100 row m-0 d-block" style={{ borderTop: "3px solid rgb(210, 210, 210)" }}>
                                                    <div className="row col-12 float-left m-0 bg-white px-0 py-2">

                                                        <div className="col-md-3 mb-4 ">
                                                            <p className="mb-1 small text-uppercase"><strong data-vouchercampo="nome"> Nome: </strong></p><span data-voucherinfo="name">{item.nome ? item.nome : "nõa informado"}</span>
                                                        </div>
                                                        <div className="col-md-3 mb-4">

                                                            <p className="mb-1 small text-uppercase"><strong data-vouchercampo="email">Telefone:</strong></p> <span data-voucherinfo="email">{/* item.telefone ? item.telefone : "não informado" */ cellphone}</span>
                                                        </div>
                                                        <div className="col-md-3 mb-4">

                                                            <p className="mb-1 small text-uppercase"><strong data-vouchercampo="email">Email:</strong> </p><span data-voucherinfo="email">{/* item.email ? item.email : "não informado" */ email}</span>
                                                        </div>



                                                        <div className="col-md-3">
                                                            <p className="mb-1 small text-uppercase"><strong data-vouchercampo="tel">Idioma:</strong></p> <span data-voucherinfo="telefone"><select id="idioma" className="form-control"/*  onchange="mudaidioma(71063);" */><option value="1">Português</option><option value="2">Inglês</option><option value="3">Espanhol</option></select></span>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div><div className="col-md-12 mt-3">
                                                <span className="col-md-12 colla p-0 mt-2 h6 text-uppercase text-primary">
                                                    <i className="fas fa-info-circle text-primary"></i> Informações Gerais</span>
                                                <div className="card rounded-0 float-left p-2 w-100 row m-0 d-block" style={{ borderTop: "3px solid rgb(210, 210, 210)" }}>
                                                    <div className="row col-12 float-left m-0 bg-white px-0 py-2">
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Data da Emissão: </strong></p><span data-voucherinfo="name">{convertDate(item.dataEmissao)}</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Fornecedor: </strong></p><span data-voucherinfo="name">{item.nomeFornecedor}</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Telefone do Fornecedor: </strong></p><span data-voucherinfo="name">{item.telefoneFornecedor}</span>
                                                        </div>


                                                    </div>
                                                    <div className="row col-12 float-left m-0 bg-white px-0 py-2">
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Agente: </strong></p><span data-voucherinfo="name">{item.resellerName}</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Referência Agente: </strong></p><span data-voucherinfo="name">{item.resellerReference}</span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-12 mt-3">
                                                <span className="col-md-12 colla p-0 mt-2 h6 text-uppercase text-primary">
                                                    <i className="fas fa-dollar-sign text-primary"></i> Dados de Faturamento</span>
                                                <div className="card rounded-0 float-left p-2 w-100 row m-0 d-block" style={{ borderTop: "3px solid rgb(210, 210, 210)" }}>
                                                    <div className="row col-12 float-left m-0 bg-white px-0 py-2">

                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Valor: </strong></p><span data-voucherinfo="name">{item.amount}</span>

                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Forma de Pagamento: </strong></p><span data-voucherinfo="name">{item.formaPagamento}</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-12 mt-3">
                                                <span className="col-md-12 colla p-0 mt-2 h6 text-uppercase text-primary">
                                                    <i className="fas fa-map text-primary">

                                                    </i>
                                                    Detalhes da Atividade
                                                </span>
                                                <div className="card rounded-0 float-left p-2 w-100 row m-0 d-block" style={{ borderTop: "3px solid rgb(210, 210, 210)" }}>
                                                    <div className="row col-12 float-left m-0 bg-white px-0 py-2">
                                                        <div className="col-md-4 mb-4">
                                                            <p className="mb-1 text-uppercase small">
                                                                <strong data-vouchercampo="email">Data:</strong>
                                                            </p>
                                                            {/* <span data-voucherinfo="email">
                                                                <a href="#" data-type="text" data-pk="1" data-edit="dataTour" data-title="Editar data da atividade" className="editable editable-click">
                                                                    {convertDate(item.dataDoTour)}
                                                                </a>
                                                            </span> */}
                                                        </div>
                                                        <div className="col-md-4 mb-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="tel">Hora:</strong></p> <span data-voucherinfo="telefone">{item.horaDoTour}</span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p className="mb-1 text-uppercase small"><strong data-vouchercampo="nome">Pax: </strong></p><span data-voucherinfo="name">{item.quantidadePax}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 mb-4">
                                                        <p className="mb-1 text-uppercase small">
                                                            <strong data-vouchercampo="tel">Endereço de Pickup:</strong>
                                                        </p>
                                                        <span>
                                                            <div id="endereco">

                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <p className="mb-1 text-uppercase small">
                                                            <strong>Local de Pickup:</strong>
                                                        </p>
                                                        <select id="qualquer" className="form-control" /* onchange="mudapickup(71063);" */>
                                                            <option value="">Selecione o pickup</option>
                                                        </select>
                                                    </div>
                                                    {/* <div className="col-md-8 mb-4">
                                                        <p className="mb-1 text-uppercase small"><strong data-vouchercampo="tel">Modalidade:</strong></p> <span><a href="#" data-type="text" data-pk="1" data-edit="endereco" data-title="Editar endereço de pickup" className="editable editable-click">Cristo Redentor em van e Escadaria Selarón (Manhã)</a></span>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-6">
                                                            <h5 className="text-success" data-vouchercampo="inclui"> <i className="fas fa-check"></i> Inclui</h5>
                                                            <ul className="inclusoes px-3 small" id="incluiVoucher">
                                                                {item.itemsIncludedDTO.length > 0
                                                                    ? item.itemsIncludedDTO.map((unit: any, index: Key) => {
                                                                        return (
                                                                            <li key={index}>{unit}</li>
                                                                        )
                                                                    })
                                                                    : 'Não informado'
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-6">
                                                            <h5 className=" text-danger" data-vouchercampo="exclui"><i className="fas fa-times"></i> Não Inclui</h5>
                                                            <ul className="exclusoes px-3 small" id="excluiVoucher">
                                                                {item.itemsNotIncludedDTO.length > 0
                                                                    ? item.itemsNotIncludedDTO.map((unit: any, index: Key) => {
                                                                        return (
                                                                            <li key={index}>{unit}</li>
                                                                        )
                                                                    })
                                                                    : 'Não informado'
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer rounded-0 border-0">
                                        <button type="button" className="btn btn-outline-primary rounded-0" data-dismiss="modal" onClick={() => { setDetailsModal(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">Fechar</span></button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>

            </>
        );
    }
}

export default DetailsReservation;