import i18next from "i18next";

const formatTimeRange = (
  time: string,
  nextTime: string | undefined,
  date: Date | string,
  productCode: string
): string => {
  const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';
  const guidedTour = process.env.REACT_APP_GUIDED_TOUR ?? "default_value";
  const sunsetTour = process.env.REACT_APP_SUPPLIER_BEACH ?? "default_value";

  const [hour, minute] = time.split(":").map(Number);

  if (guidedTour.includes(productCode) || sunsetTour.includes(productCode)) {
    return `${hour.toString().padStart(2, "0")}h${minute > 0 ? minute : ""}`;
  }

  const validDate = typeof date === "string" ? new Date(date) : date;

  if (!(validDate instanceof Date) || isNaN(validDate.getTime())) {
    throw new Error(
      "Data inválida. Certifique-se de fornecer uma data válida."
    );
  }

  if (isNaN(hour) || isNaN(minute)) {
    throw new Error(
      "Horário inválido. Certifique-se de que está no formato HH:mm."
    );
  }

  const dayOfWeek = validDate.getDay();

  if (!nextTime) {
    let today: any = new Date().toLocaleString('pt');

    let dateNow = today.replaceAll(',','').split(" ")[0].split("/").reverse().join("");
    let timeNow = today.replaceAll(',','').split(" ")[1].split(":").splice(0, 2).join("");

    
    if (`${dateNow}${timeNow}` < "202501310000") {
      if (hour === 18) {
        return `18h ${lng === "EN" || lng === "ES" ? "-" : "às"} 19h`;
      }
    } else {
      if (hour === 18 && (dayOfWeek === 4 || dayOfWeek === 5 || dayOfWeek === 6)) {
        return `18h ${lng === "EN" || lng === "ES" ? "-" : "às"} 19h`;
      }
      if (hour === 18) {
        return `18h ${lng === "EN" || lng === "ES" ? "-" : "às"} 18h30`;
      }
    }
  }

  const timeDate = new Date(validDate);
  timeDate.setHours(hour, minute, 0, 0);
  const timeTimestamp = timeDate.getTime();

  let nextHour = hour + 1;
  let nextMinute = minute;

  if (nextTime) {
    const [nextHourInput, nextMinuteInput] = nextTime.split(":").map(Number);
    const nextTimeDate = new Date(validDate);
    nextTimeDate.setHours(nextHourInput, nextMinuteInput, 0, 0);
    const nextTimeTimestamp = nextTimeDate.getTime();

    const diffMinutes = (nextTimeTimestamp - timeTimestamp) / (1000 * 60);

    if (diffMinutes >= 60) {
      nextHour = hour + 1;
      nextMinute = minute;
    } else {
      nextHour = nextHourInput;
      nextMinute = nextMinuteInput;
    }
  }

  return `${hour.toString().padStart(2, "0")}h${minute ? `${minute.toString().padStart(2, "0")}` : ""} ${lng === "EN" || lng === "ES" ? "-" : "às"} ${nextHour
    .toString()
    .padStart(2, "0")}h${nextMinute ? `${nextMinute.toString().padStart(2, "0")}` : ""}`;
};

export default formatTimeRange;