import React from "react";

function LocationPT() {
    return (
        <>
            <div className="p-3">
                <div className="top">
                    <p>Estamos localizados na Av Pasteur, 520 - Urca, Rio de Janeiro.</p>
                </div>
                <h6>De Metrô</h6>
                <p>Desembarque na estação Botafogo, pela saída E - Mena Barreto.Caminhe até o ponto final do ônibus 513 na Rua Voluntários da Pátria e desça na UNIRIO. De lá, caminhe 260m até o Parque Bondinho.</p>
                <h6>De Bicicleta</h6>
                <p>O Parque Bondinho não dispõe de um bicicletário. Mas você pode deixar a bicicleta próximo à banca de jornal em frente ao restaurante Árabe da Urca, bem próximo ao Parque Bondinho. Traga seu cadeado. Na Av Pasteur também está disponível, um pouco antes, uma estação de Bike Itaú.</p>
                <h6>De Carro</h6>
                <p>Não possuímos estacionamento rotativo. Visitantes do Parque Bondinho têm benefício exclusivo e pagam somente R$18 na diária do estacionamento do Botafogo Praia Shopping (Praia de Botafogo, 400 – Botafogo, RJ). Na hora de ir embora, basta se direcionar ao 4º andar, até a atendente no guichê de estacionamento. Lá, você apresenta seu bilhete do Parque Bondinho (físico ou em PDF) para ativar o desconto.</p>
                <strong>ATENÇÃO! ORIENTAMOS NÃO VIR DE CARRO.</strong> <br /><br />
                <h6>De Barca</h6>
                <p>O visitante de Niterói, Ilha do Governador e Paquetá pode pegar a barca que para na Praça XV. De lá, caminhe até o Largo da Carioca (740m), ou pegue a Linha 2 do VLT até a estação Cristiano Ottoni - Pequena África, e pegue o ônibus 107 (Central). Desça na UNIRIO e caminhe 180m até o Parque Bondinho.</p>
                <div className="bottom">
                    <h6>De Ônibus</h6>
                    <p>As principais linhas para chegar ao Parque Bondinho são:</p>
                    <ul>
                        <li>107 - Central - Urca (Brs 1 - via Praia do Flamengo)</li>
                        <li>513SP - Urca - Metrô Botafogo (Via Rio Sul - Integração com metrô)</li>
                        <li>518 - Urca - Botafogo (via Copacabana)</li>
                        <li>519 - Urca - Copacabana (via Botafogo)</li>
                        <li>167 - Terminal Gentileza - Urca (Circular - Via Marcello Alencar)</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LocationPT;